import React, {FC} from 'react';
import styled from "styled-components";

import {MfLink} from '@bnppf-ui-library/mflink';

interface LinkProps {
    id?: string,
    href?: string
    text: string
    onClick?: () => void
    target?:string
}

const StyledLink = styled.a`
    color: ${({theme}) => theme.color.primary.default} !important;
    font-family: ${({theme}) => theme.font.family.desktop.bold} !important;
    text-decoration: none;
`;

export const Link: FC<LinkProps> = ({href, text, onClick, id, target}) => {
    return (
        <StyledLink as={MfLink} id={id} href={href} target={target} onClick={event => {
            if(!onClick) {
                return;
            }
            event.preventDefault();
            onClick();
        }}>
            {text}
        </StyledLink>
    )
}

Link.defaultProps = {
    href: '#'
}
