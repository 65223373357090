import React, {useEffect} from 'react';
import { MfContent, MfRow } from '@bnppf-ui-library/mfcontent';
import {HeaderProps} from 'components/HeaderProps';
import { MfButton } from "@bnppf-ui-library/mfbutton";
import { MfSpacer } from "@bnppf-ui-library/mfspacer";
import { MfTypo } from '@bnppf-ui-library/mftypo';
import { MfList } from '@bnppf-ui-library/mflist';
import DOMPurify from 'dompurify';


import { translate } from "helpers/i18n";
import ConfirmationImage from "assets/svg/confirmation.svg";
import styled from 'styled-components';
import { BNP_HOME_URL } from 'helpers/constants';
import {getLanguage} from "helpers/getLanguage";
import {register, Scenario} from "helpers/analytics/analytics";


const ConfirmImage = styled.img`
    width: 264px;
    height: 176px;
`;

const Disclaimer = styled.span`
    & > a {
        color: ${({ theme }) => theme.color.primary.default} !important;
        font-family: ${({ theme }) => theme.font.family.desktop.bold} !important;
        text-decoration: none;

        &:hover {
            text-decoration: underline;
        }
    }
`;

const CenteredMFTypo = styled.h1`
    text-align: center;
`;

export const ConfirmationPage = () => {

    const handleCloseButton = () => {
        const language = getLanguage();
        window.location.href = BNP_HOME_URL[language];
    }

    useEffect(() => {
        register(Scenario.CONFIRMATION_PAGE);
    }, []);

    return (
        <>
            <MfContent header={<HeaderProps pathName="/confirm" />}
                actions={[
                    <MfButton key="next" onClick={handleCloseButton}>
                        {translate('confirmation.button')}
                    </MfButton>,
                ]}>
                <MfSpacer size="5" />
                <MfRow>
                    <ConfirmImage alt="confirmationImage" src={ConfirmationImage} />
                </MfRow>
                <MfSpacer size="4" />
                <MfRow>
                    <CenteredMFTypo as={MfTypo} variant="title3" weight="bold" >
                        {translate('confirmation.title')}
                    </CenteredMFTypo>
                </MfRow>
                <MfSpacer size="5" />
                <MfRow>
                    <MfList>
                        <li type="check">{translate('confirmation.list_1')}</li>
                    </MfList>
                </MfRow>
                <MfSpacer size="3" />
                <MfRow>
                    <MfTypo variant="bodytext1">
                        <Disclaimer dangerouslySetInnerHTML={{
                            __html: DOMPurify.sanitize(translate('confirmation.footertext'), {
                                ALLOWED_TAGS: ['a'],
                                ALLOWED_ATTR: ['href', 'target']
                            })
                        }} />
                    </MfTypo>
                </MfRow>
                <MfSpacer size="5" />
            </MfContent>
        </>
    );
}

