import React, {useCallback} from "react";
import styled from "styled-components";

import {Link} from "components/link";
import {translate} from "helpers/i18n";
import {getLanguage} from "helpers/getLanguage";

const FooterWrapper = styled.footer`
    padding: 1rem 0;
    margin-top: 20px;
    color: grey;
    background-color: white;
    border-top: 1px solid #dddddd;
    text-align: center;

    & a {
        margin-right: 1rem;
        font-size: 0.875rem;

        &:hover {
            text-decoration: none;
            font-size: 0.875rem;
        }
    }
`;

export const Footer = () => {
    const handleCookie = useCallback(() => {
        window.PageBus.publish('cc.policy.popup');
    }, []);

    return (
        <FooterWrapper>
            <Link
                target="_blank"
                text={translate("footer.disclaimerLink")}
                href={getLanguage() === "FR" ? "https://www.bnpparibasfortis.be/fr/public/mention-legale" : "https://www.bnpparibasfortis.be/nl/public/disclaimer"}
            />
            <Link
                target="_blank"
                text={translate("footer.privacyLink")}
                href={getLanguage() === "FR" ? "https://www.bnpparibasfortis.be/fr/public/vie-privee" : "https://www.bnpparibasfortis.be/nl/public/uw-privacy"}
            />
            <Link
                text={translate("footer.cookieLink")}
                onClick={handleCookie}
            />
        </FooterWrapper>
    );
};