import styled from "styled-components"

export const BaseLink = styled.a`
  font-family: inherit;
  color: inherit;
  font-size: inherit;
  text-decoration: underline;
  cursor: pointer;

  &:visited,
  &:active,
  &:hover {
    font-family: inherit;
    color: inherit;
    font-size: inherit;
    text-decoration: underline;
    cursor: pointer;
  }
`
