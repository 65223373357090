import React, {useEffect} from 'react';
import {MfContent, MfRow} from '@bnppf-ui-library/mfcontent';
import {MfTypo} from '@bnppf-ui-library/mftypo';
import {MfSpacer} from "@bnppf-ui-library/mfspacer";
import {MfSingleSelect, MfTextOption} from '@bnppf-ui-library/mfselect';
import {HeaderProps} from 'components/HeaderProps';
import {translate} from "helpers/i18n";
import {CIVIL_STATUS} from "reducers/deathDeclarationReducer";
import {useHistory} from "react-router-dom";
import {useAppDataContext} from "contexts/app-data";
import {ACTIONS} from "helpers/constants";
import {register, Scenario} from "helpers/analytics/analytics";

export const CivilStatusFrom = () => {
    const history = useHistory();
    const {state, dispatch} = useAppDataContext();
    const handleMaritalStatusSelection = (value: CIVIL_STATUS) => {
        dispatch({
            type: ACTIONS.SET_CIVIL_STATUS,
            civilStatus: value
        });
        history.push({pathname: "/summary"})
    }

    useEffect(() => {
        register(Scenario.DECEASED_MARITAL_STATUS_PAGE);
    }, []);

    return (
        <MfContent
            header={<HeaderProps pathName="/civilstatus"/>}
        >
            <MfSpacer size="5"/>
            <MfRow>
                <MfTypo as="h3" variant="title3" weight="bold">
                    {translate('deceased_civil_status_screen.title')}
                </MfTypo>
            </MfRow>
            <MfSpacer size="4"/>
            <MfRow>
                <MfSingleSelect
                    value="-1"
                    initialHighlighted={state.deceased.civilStatus}
                    onChange={handleMaritalStatusSelection}>
                    <MfTextOption
                        value={CIVIL_STATUS.MARRIED}
                        label={translate('deceased_civil_status_screen.married')}
                    />
                    <MfTextOption
                        value={CIVIL_STATUS.DIVORCED}
                        label={translate('deceased_civil_status_screen.divorced')}
                    />
                    <MfTextOption
                        value={CIVIL_STATUS.WIDOW}
                        label={translate('deceased_civil_status_screen.widow')}
                    />
                    <MfTextOption
                        value={CIVIL_STATUS.SINGLE}
                        label={translate('deceased_civil_status_screen.single')}
                    />
                    <MfTextOption
                        value={CIVIL_STATUS.LEGALLY_COHABITING}
                        label={translate('deceased_civil_status_screen.legal_partner')}
                    />
                    <MfTextOption
                        value={CIVIL_STATUS.OTHER}
                        label={translate('deceased_civil_status_screen.other')}
                    />
                </MfSingleSelect>
            </MfRow>
            <MfSpacer size="6"/>
        </MfContent>
    );
}

