
import { getLanguage } from "helpers/getLanguage";
import { BASE_URL } from '../env';

const isEmpty = (value: string) => value.length === 0;
const isEmptyPhoneNumber = (value: string) => isEmpty(value.replace("+32", ""));

const getContactPreference = (contactMethods) => {
   if (contactMethods.length > 1) {
       return 'EMAIL_PHONE'
   }
   if (contactMethods.indexOf('PHONE') !== -1) {
       return 'TELEPHONE'
   }
   if (contactMethods.indexOf('EMAIL') !== -1) {
       return 'EMAIL'
   }
   return null;
};

export const SubmitDeathDeclaration = (state) => {

    const phoneFixed = isEmptyPhoneNumber(state.contactNumber.fixedPhoneNumber) ? null : {
        "selected": "FIXED",
        "prefix": state.contactNumber.phoneFixed.prefix,
        "value": state.contactNumber.phoneFixed.value
    };
    const phoneGsm = isEmptyPhoneNumber(state.contactNumber.gsmPhoneNumber) ? null : {
        "selected": "GSM",
        "prefix": state.contactNumber.phoneGsm.prefix,
        "value": state.contactNumber.phoneGsm.value
    };
    const postalInfo = {
        "street": state.declarant.postalAddress.street,
        "number": state.declarant.postalAddress.number,
        "box": state.declarant.postalAddress.box,
        "postalCode": state.declarant.postalAddress.postalCode,
        "municipality": state.declarant.postalAddress.municipality,
        "country": state.declarant.postalAddress.country.name.substring(0, 20),
    }

    const declarantInfo = {
        firstName: state.declarant.firstname,
        lastName: state.declarant.lastname,
        emailAddress: state.declarant.emailAddress,
        relationshipToDeceased: state.declarant.relationshipToDecease,
        deliveryMode: state.declarant.isDeclarantSPOC === "UNKNOWN" ? null : state.declarant.deliveryMode,
        declarantSPOC: state.declarant.isDeclarantSPOC,
        postal: state.declarant.deliveryMode === "POSTAL" ? postalInfo : null,
        phoneFixed: state.declarant.isDeclarantSPOC === "YES" ? phoneFixed : null,
        phoneGsm: state.declarant.isDeclarantSPOC === "YES" ? phoneGsm : null,
        contactPref: state.declarant.isDeclarantSPOC !== 'UNKNOWN' ? getContactPreference(state.declarant.contactMethod) : null
    };
    const contactPersonInfo = {
        firstName: state.declarant.isDeclarantSPOC === "YES" ? state.declarant.firstname : state.contact.firstname,
        lastName: state.declarant.isDeclarantSPOC === "YES" ? state.declarant.lastname : state.contact.lastname,
        emailAddress: state.declarant.isDeclarantSPOC === "YES" ? state.declarant.emailAddress : state.contact.emailAddress,
        relationshipToDeceased: state.declarant.isDeclarantSPOC === "YES" ? state.declarant.relationshipToDecease : state.contact.relationshipToDecease,
        phoneFixed,
        phoneGsm
    }
    const deceasedInfo = {
        firstName: state.deceased.firstname,
        lastName: state.deceased.lastname,
        dateOfBirth: state.deceased.birthdate,
        dateOfDeceased: state.deceased.dateOfDeath,
        causeOfDeath: state.deceased.causeOfDeath,
        accountNumber: state.deceased.accountNumber ? 'BE'+state.deceased.accountNumber : null,
        civilStatusCode: state.deceased.civilStatus,
    };
    const summaryData = {
        language: getLanguage(),
        documentIdList: state.filesList.map((file) => file.id),
        contact: state.declarant.isDeclarantSPOC === "UNKNOWN" ? null : contactPersonInfo,
        declarant: declarantInfo,
        deceased: deceasedInfo
    };
    const requestOptions: RequestInit = {
        method: 'POST',
        headers: {
            "Content-Type": "application/json",
            "X-Correlation-ID": state.uniqueID
        },
        body: JSON.stringify(summaryData),
        redirect: 'follow'
    };

    return fetch(`${BASE_URL}/party/death-declaration-public/v2/death-declarations`, requestOptions).then(response => {
        if (!response.ok) {
            throw new Error("API Error");
        } else {
            response.text()
        }
    });

}